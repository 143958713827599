import { FooterSimple } from "@common/footer"

export default function LandingSimpleLayout({ children, footerLinks }) {
  return (
    <div className="flex flex-col min-h-screen bg-background">
      <main className="flex-grow">{children}</main>
      <footer>
        <FooterSimple footerLinks={footerLinks} />
      </footer>
    </div>
  )
}

// context - read option 4 (bonus) here https://adamwathan.me/2019/10/17/persistent-layout-patterns-in-nextjs/
export const getLayout = (page, footerLinks) => (
  <LandingSimpleLayout footerLinks={footerLinks}>{page}</LandingSimpleLayout>
)
