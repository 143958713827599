import Button from "@common/buttons/button"
import Form from "@common/forms/form"
import Modal from "@common/modal/modal"
import { getResetPasswordToken } from "@common/network"
import { useState } from "react"

export default function ForgotPassword({ contactEmail }: { contactEmail: string }) {
  const resetForm = {
    email: {
      field: "input",
      type: "email",
      label: {
        label: "Email",
      },
      value: "",
      required: true,
    },
  }
  const [forgotModalOpen, setForgotModalOpen] = useState(false)
  const [forgotSubmitted, setForgotSubmitted] = useState(false)

  function handleForgotClose() {
    setForgotModalOpen(false)
    setForgotSubmitted(false)
  }
  return (
    <>
      {renderForgotModal()}
      <div className="flex items-center justify-between w-full">
        <p>Password *</p>
        <Button
          tabIndex={-1}
          type="button"
          onClick={(e: any) => {
            e.stopPropagation()
            setForgotModalOpen(true)
          }}
          size="xs"
          variant="minimal"
        >
          Forgot your password?
        </Button>
      </div>
    </>
  )

  function renderForgotModal() {
    return (
      <Modal isOpen={forgotModalOpen} onClose={() => handleForgotClose()}>
        <Modal.Layout widthClasses="sm:max-w-lg sm:w-full">
          {forgotSubmitted == false ? (
            //@ts-ignore
            <Form
              formHeader="Reset your Password"
              formConfig={resetForm}
              formSubText={
                "Enter the email address associated with your account and we'll send you a link to reset your password."
              }
              primaryAction="Continue"
              isSingleFullButton={true}
              submitFunction={getResetPasswordToken}
              onSubmitSuccess={() => {
                setForgotSubmitted(true)
              }}
              forceActivePrimaryButton={true}
            />
          ) : (
            <div className="p-3 text-primary">
              <div className="header-2">
                Thanks, you’ll receive an email with instructions to reset your
                password.
              </div>
              <br />
              <div className="text-sm">If not, do reach out to {contactEmail}.</div>
            </div>
          )}
        </Modal.Layout>
      </Modal>
    )
  }
}
