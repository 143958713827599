/* eslint-disable no-undef */
import { useRouter } from "next/router"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { gLogin, updateAccountDetails, useIsLoggedIn } from "@common/network"
import { useToast } from "@common/toast/toastProvider"
import { hitUrl, isNullOrEmpty } from "@common/lib/util"
import { Status } from "@common/types"
import Button from "@common/buttons/button"
import Svg from "@common/svg"
import { getHomePath } from "@common/lib/navigation"

export default function GoogleLogin({ configHomePath, site }) {
  const router = useRouter()
  const { showPromiseToast } = useToast()
  const [scriptLoadedSuccessfully, setScriptLoadedSuccessfully] = useState(false)
  const { data } = useIsLoggedIn("google_oauth")
  const clientId = data?.google_client_id
  const { redirect_path } = router.query
  const clientRef = useRef()
  const onSuccessRef = useRef(onSuccess)
  onSuccessRef.current = onSuccess

  const onErrorRef = useRef(onError)
  onErrorRef.current = onError

  useEffect(() => {
    const scriptTag = document.createElement("script")
    scriptTag.src = "https://accounts.google.com/gsi/client"
    scriptTag.async = true
    scriptTag.defer = true
    scriptTag.onload = () => {
      setScriptLoadedSuccessfully(true)
    }
    scriptTag.onerror = () => {
      setScriptLoadedSuccessfully(false)
    }

    document.body.appendChild(scriptTag)

    return () => {
      document.body.removeChild(scriptTag)
    }
  }, [])

  useEffect(() => {
    if (!scriptLoadedSuccessfully || !clientId) return
    const clientMethod = "initTokenClient"

    const client = window?.google?.accounts?.oauth2[clientMethod]({
      client_id: clientId,
      scope: `email profile`,
      callback: (response) => {
        if (response.error) return onErrorRef.current?.(response)
        onSuccessRef.current?.(response)
      },

      error_callback: (nonOAuthError) => {
        console.log("nonOAuthError", nonOAuthError)
      },
    })

    clientRef.current = client
  }, [clientId, scriptLoadedSuccessfully])

  const login = useCallback(() => clientRef.current?.requestAccessToken(), [])

  async function onSuccess(response = {}) {
    const { access_token } = response
    const { data, status } = await showPromiseToast(
      gLogin({ credential: access_token }),
      {
        error: "Login Failed. Try again!",
        pending: "Logging In",
        success: "Logged In Successfully!",
      }
    )
    if (!isNullOrEmpty(data) && status == Status.Success) {
      handleOnSubmit()
      let home_path
      if (data.home_path) ({ home_path } = data)
      else {
        home_path = getHomePath(data?.cust_id, configHomePath)
        updateAccountDetails({ home_path })
      }
      const path =
        data?.cust_id && redirect_path?.includes("{cust_id}")
          ? redirect_path.replace(/{cust_id}/, data.cust_id)
          : home_path
      router.push(path)
    }
  }

  function onError(error) {
    console.log("error", error)
  }

  function handleOnSubmit() {
    const email = encodeURIComponent("google_signin")
    const _site = encodeURIComponent(site)
    // 🪧 same form URL used for login and signup
    const url = `https://docs.google.com/forms/d/e/1FAIpQLScGALxdR8fehXEr7TJP-9eIO6NIwBzKF1kUUOkGvT7SF6JC7w/formResponse?usp=pp_url&entry.2039695576=${null}&entry.1824555110=${email}&entry.615213946=${null}&entry.1738298124=${"login"}&entry.11348956=${_site}`
    hitUrl(url)
  }

  return (
    <div id="signUpDiv">
      <Button width="full" variant="outline" onClick={login}>
        <Svg name="google" />
        <span className="font-semibold"> Sign in with Google</span>
      </Button>
    </div>
  )
}
