import { useRouter } from "next/router"
import { useToast } from "@common/toast/toastProvider"
import { verifyOtp, resendOtp } from "@common/network"
import Form from "@common/forms/form"
import { Status } from "@common/types"

export default function OtpForm({ onSubmitResponse }) {
  const { updateToast, showToast } = useToast()
  const otpForm = {
    otp: { field: "input", label: { label: "OTP" }, value: "", required: true },
  }

  const router = useRouter()

  const otp =
    "otp" in router.query && !isNaN(parseInt(router.query["otp"]))
      ? parseInt(router.query["otp"])
      : null
  otpForm["otp"]["value"] = otp

  return (
    <Form
      formHeader="Verify your email"
      formConfig={otpForm}
      formSubText={
        <div className="text-sm-r font-normal text-secondary text-left">
          To continue, please enter the 6-digit verification code sent to your email.{" "}
          <br />
          <br /> Didn't receive a code?{" "}
          <button
            className="link-blue cursor-pointer"
            onClick={() => handleResendClick()}
          >
            Resend
          </button>
          .
        </div>
      }
      primaryAction="Verify"
      isSingleFullButton={true}
      formBottomText={
        <span>
          Don't have an account?{" "}
          <a href="/signup" className="link-blue">
            Signup
          </a>
        </span>
      }
      submitFunction={verifyOtp}
      onSubmitResponse={handleSubmitResponse}
      forceActivePrimaryButton={true}
    />
  )

  function handleSubmitResponse(data) {
    if (onSubmitResponse) onSubmitResponse(data)
  }

  async function handleResendClick() {
    const toastId = showToast({ message: "Resending...", type: "loading" })
    try {
      const { message, status } = await resendOtp()
      if (status === Status.Success) {
        updateToastWithMessage(toastId, "success", "OTP has been resent.")
      } else {
        updateToastWithMessage(toastId, "error", message, false)
      }
    } catch (error) {
      updateToastWithMessage(toastId, "error", "OTP not sent!")
    }
  }

  function updateToastWithMessage(toastId, type, message, autoClose = true) {
    const render = typeof message === "string" ? message : <div>{message}</div>
    updateToast(toastId, { type, message: render, autoClose })
  }
}
