import { replaceAll } from "@common/lib/util"
import { round } from "@common/lib/util"

/**
 * Converts a number of seconds into a structured time object and a human-readable string.
 *
 * @param {number} seconds - The total number of seconds to be converted. Should be a non-negative integer.
 * @param {number} [maxParts=2] - Optional. The maximum number of time units to include in the readable string.
 * @returns {{timeObject: Object, readableString: string}} An object containing:
 *           - timeObject: An object with keys as time units (years, months, weeks, days, hours, minutes, seconds)
 *             and their corresponding values.
 *           - readableString: A string combining up to `maxParts` of the most significant time units.
 *
 * @example
 * // returns { timeObject: { years: 0, months: 3, weeks: 4, days: 2, hours: 13, minutes: 46, seconds: 40 },
 * // readableString: "3 months, 4 weeks, 2 days" }
 * const result = getReadableStrFromSeconds(10000000, 3);
 *
 * @description This function is useful for applications where a duration needs to be displayed in a
 * human-friendly format, such as in UI displays, logging, or reporting. The function assumes an average
 * month length of 30 days and a year length of 365 days for conversion. These are approximations and
 * might not suit applications requiring precise date calculations.
 */
export function getReadableStrFromSeconds(seconds, maxParts = 2) {
  const years = Math.floor(seconds / (3600 * 24 * 365))
  const months = Math.floor((seconds % (3600 * 24 * 365)) / (3600 * 24 * 30))
  const weeks = Math.floor((seconds % (3600 * 24 * 30)) / (3600 * 24 * 7))
  const days = Math.floor((seconds % (3600 * 24 * 7)) / (3600 * 24))
  const hours = Math.floor((seconds % (3600 * 24)) / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const remainingSeconds = seconds % 60

  const timeUnits = [
    { unit: "years", value: years },
    { unit: "months", value: months },
    { unit: "weeks", value: weeks },
    { unit: "days", value: days },
    { unit: "hrs", value: hours },
    { unit: "mins", value: minutes },
    { unit: "secs", value: remainingSeconds },
  ]

  const parts = timeUnits
    .filter((u) => u.value > 0)
    .slice(0, maxParts)
    .map((u) => round(u.value, 0) + " " + u.unit)

  const readableString = parts.join(", ")

  return {
    timeObject: timeUnits.reduce((obj, item) => {
      obj[item.unit] = item.value
      return obj
    }, {}),
    readableString,
  }
}

// input - Fri Feb 13 2009 18:31:30 GMT-0500 (EST)
// output - 20090213
export function getDateDigitsFromDateObj(string) {
  const epoch = getEpochFromDateStr(string)
  return getDateDigitsFromEpoch(epoch)
}

// input - 20090213
// output - Fri Feb 13 2009 18:31:30 GMT-0500 (EST)
export function getDateObjFromDateDigits(digits) {
  const dateSeparator = getDateWithSeparator(digits)
  const date = new Date(dateSeparator)
  return date
}

// input - Fri Feb 13 2009 18:31:30 GMT-0500 (EST)
// output - epoch
export function getEpochFromDateStr(string) {
  return new Date(string).getTime() / 1000
}
// input - 20090213
// output - epoch
export function getEpochFromDateDigits(string) {
  const dateString = getDateWithSeparator(string)
  return new Date(dateString).getTime() / 1000
}

// input epoch
// output - Fri Feb 13 2009 18:31:30 GMT-0500 (EST)
export function getDateObjFromEpoch(epoch) {
  var d = new Date(0) // The 0 there is the key, which sets the date to the epoch
  d.setUTCSeconds(epoch)
  return d
}

// input 20181230
// output 2018/12/30
export function getDateWithSeparator(date) {
  if (!date) return date
  const year = date.substring(0, 4)
  const month = date.substring(4, 6)
  const day = date.substring(6, 8)
  const str = year + "/" + month + "/" + day
  return str
}

// returns a date string of the format "9 August, 2017" in IST given epoch
export function getDateStrFromEpoch(epoch) {
  if (!epoch) return
  // epoch is received in GMT, even thought it is actually the epoch of original source. So we display date in GMT (which is actually the original time zone of source)
  const date = new Date(epoch * 1000).toLocaleString("en-US", {
    timeZone: "Europe/London",
    day: "numeric",
    month: "long",
    year: "numeric",
  })

  const utcDateStr = date

  return utcDateStr
}

// returns a date string of the format "3PM, 9 August, 2017" in IST given epoch
/**
 *
 * @param {number} epoch -> In seconds
 * @returns
 */
export function getDateTimeStrFromEpoch(epoch) {
  // epoch is received in GMT, even thought it is actually the epoch of original source. So we display date in GMT (which is actually the original time zone of source)
  const date = new Date(epoch * 1000)
  const dateTime = new Intl.DateTimeFormat("en-US", {
    timeStyle: "short",
    dateStyle: "medium",
    hour12: true,
  })

  if (isValidDate(date)) return dateTime.format(date)
  else return date.toString()
}

// input - epoch
// output - 20201117
export function getDateDigitsFromEpoch(epoch) {
  let date = getDateReadableFromEpoch(epoch)
  date = replaceAll(date, "/", "") //date.split('/').join('')
  return date
}

// input - epoch
// output - 2020/11/17
export function getDateReadableFromEpoch(epoch) {
  var date = new Date(epoch * 1000)
  var year = date.getFullYear()
  var month = ("0" + (date.getMonth() + 1)).slice(-2)
  var day = ("0" + date.getDate()).slice(-2)

  // var hours = date.getHours()
  // var minutes = date.getMinutes()
  // var seconds = date.getSeconds()

  return year + "/" + month + "/" + day
}

export function isValidDate(dateString) {
  const date = new Date(dateString)
  return date instanceof Date && !isNaN(date)
}

// input - epoch
// output - Today, 3 days ago, 1 week ago, etc
export function getTimeSinceFromEpoch(epoch) {
  const date = new Date(epoch * 1000)
  var seconds = Math.floor((new Date() - date) / 1000)

  var interval = seconds / 31536000
  if (interval > 2) return Math.floor(interval) + " years ago"
  else if (interval > 1) return Math.floor(interval) + " year ago"

  interval = seconds / 2592000
  if (interval > 2) return Math.floor(interval) + " months ago"
  else if (interval > 1) return Math.floor(interval) + " month ago"

  interval = seconds / 86400
  if (interval > 2) return Math.floor(interval) + " days ago"
  else if (interval > 1) return Math.floor(interval) + " day ago"

  interval = seconds / 3600
  if (interval > 2) return Math.floor(interval) + " hours ago"
  else if (interval > 1) return Math.floor(interval) + " hour ago"

  interval = seconds / 60
  if (interval > 2) return Math.floor(interval) + " minutes ago"
  else if (interval > 1) return Math.floor(interval) + " minute ago"

  return Math.floor(seconds) + " seconds ago"
}
