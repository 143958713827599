import { getLogin } from "@common/network"
import Form from "@common/forms/form"

export default function LoginForm({ onSubmitResponse, config }) {
  function handleSubmitResponse(data) {
    onSubmitResponse?.(data)
  }
  return (
    <>
      <Form
        formConfig={config}
        primaryAction="Sign In"
        isSingleFullButton={true}
        formBottomText={
          <span>
            Don't have an account?{" "}
            <a href="/signup" className="link-blue">
              Signup
            </a>
          </span>
        }
        submitFunction={getLogin}
        onSubmitResponse={handleSubmitResponse}
        forceActivePrimaryButton={true}
      />
    </>
  )
}
