import React, { useState } from "react"
import { getQuickLogin } from "@common/network"
import Form from "@common/forms/form"
import Modal from "@common/modal/modal"
import Alert from "@common/alert"

export default function Magiclink({
  onSubmitResponse,
  config,
  contactEmail,
  homePath,
  redirectPath,
}) {
  const [showModal, setShowModal] = useState(false)
  const [isError, setIsError] = useState(false)
  function handleSubmitResponse(data) {
    setShowModal(true)
    if (isError) {
      setIsError(true)
    }
    if (onSubmitResponse) onSubmitResponse(data)
  }
  return (
    <>
      <Form
        formConfig={config}
        primaryAction="Continue"
        isSingleFullButton={true}
        submitFunction={({ email }) =>
          getQuickLogin({
            email,
            home_path: homePath,
            redirect_path: redirectPath,
          })
        }
        onSubmitResponse={handleSubmitResponse}
        forceActivePrimaryButton={true}
      />
      {renderNotifierModal()}
    </>
  )

  function renderNotifierModal() {
    return (
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        crossButton={false}
      >
        <Modal.Layout widthClasses="w-full max-w-md">
          {!isError ? (
            <>
              <div className="flex justify-center opacity-90">
                <img src="./images/landing/mail.svg" />
              </div>
              <div className="text-lg mt-3 font-semibold dark:text-gray-400">
                Thanks, you’ll receive an email with login link.
              </div>
              <div className="text-sm  mt-3 dark:text-gray-400">
                If not, do reach out to{" "}
                <span className="font-semibold">{contactEmail}</span>.
              </div>
            </>
          ) : (
            <div className="px-5 py-10">
              <Alert variant="error">
                Something went wrong, please try again later.
              </Alert>
            </div>
          )}
        </Modal.Layout>
      </Modal>
    )
  }
}
