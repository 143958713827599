import { getLayout } from "@common/layouts/landingSimpleLayout"
import LoginPage from "@common/pages/loginPage"
import { NextPage, NextPageContext } from "next"
import { SITE_CONFIG } from "configs/site"
import { getIsLoggedIn } from "@common/network"

Login.getLayout = (page: NextPage) => getLayout(page, SITE_CONFIG.footerLinks)

Login.getInitialProps = async (ctx: NextPageContext) => {
  const cookie = ctx.req
    ? { withCredentials: true, headers: { cookie: ctx.req.headers.cookie } }
    : {}

  return await getIsLoggedIn(cookie)
}

export default function Login({ data }: any) {
  return <LoginPage siteConfig={SITE_CONFIG} data={data} />
}
