import AuthComponent from "@common/auth/authComponent"
import { VerticalCenterContainer } from "@common/container"
import Logo from "@common/logo"
import Seo from "@common/seo"
import { LoginTypeEnum } from "@common/types"
import { useTheme } from "next-themes"
import React from "react"
import Container from "@common/v2/container"

export default function LoginPage({ siteConfig, data, logoVersion }: any) {
  const { theme } = useTheme()
  const {
    contactEmail,
    loginType = [LoginTypeEnum.Login],
    homePath,
    site,
    seo,
  } = siteConfig

  return (
    <>
      <Seo title={`Login - ${seo?.siteName}`} />
      <Container size="landing">
        <VerticalCenterContainer minusValue={204}>
          <div className="flex items-center justify-center py-8">
            <div className="h-12">
              <Logo
                logoObj={siteConfig["logo"]}
                isDarkMode={theme == "dark"}
                url="/"
                version={logoVersion}
              />
            </div>
          </div>
          <div className="mx-auto w-full max-w-lg border rounded-md p-10 sm:p-14">
            <AuthComponent
              data={data}
              loginType={loginType}
              configHomePath={homePath}
              contactEmail={contactEmail}
              site={site}
            />
          </div>
        </VerticalCenterContainer>
      </Container>
    </>
  )
}
